<template>
  <!-- 活动管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <!-- <el-button type="success" icon="el-icon-plus" size="mini">新增网点</el-button> -->
      <div class="right-search">
        <span>所属地区：</span>
        <el-select v-model="area" clearable filterable :disabled="loading" placeholder="请选择" size="mini" style="width: 160px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">团队经理：</span>
        <el-select v-model="fcode" placeholder="请选择" :disabled="loading" filterable clearable size="mini" style="width: 180px;">
          <el-option
            v-for="item in fnameOptions"
            :key="item.empcode"
            :label="`${item.empname} ${item.emparea ? '（'+item.emparea+'）' : ''}`"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">规划师：</span>
        <el-select v-model="scode" placeholder="请选择" :disabled="loading" filterable clearable size="mini" style="width: 160px;">
          <el-option
            v-for="item in snameOptions"
            :key="item.id"
            :label="item.empname"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">活动月份：</span>
        <el-date-picker
          v-model="month"
          type="month"
          size="mini"
          :clearable="false"
          :disabled="loading"
          placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" size="mini" :disabled="loading" style="margin: 0px 20px;" @click="onSearch">搜索</el-button>
      </div>
    </div>
    <div style="margin-top: 15px;display: flex;justify-content: space-between;">
      <el-radio-group v-model="activeState" :disabled="loading" size="mini">
        <el-radio-button label="0">所有活动</el-radio-button>
        <el-radio-button label="1">待审核</el-radio-button>
        <el-radio-button label="2">已通过</el-radio-button>
        <el-radio-button label="3">未通过</el-radio-button>
      </el-radio-group>
      <el-button type="success" size="mini" icon="el-icon-download" v-if="!loading" style="margin-left: 15px;" @click="exportMonthData">导出本月数据</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
      style="width: 100%; font-size: 12px; margin: 20px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        fixed
        label="规划师"
        width="100">
        <template slot-scope="scope">
          <div>
            {{ scope.row.empname }}
            <el-tag v-if="scope.row.empstate == 2" type="warning" size="mini">离职</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fname"
        label="所属团队"
        width="100">
      </el-table-column>
      <el-table-column
        prop="area"
        label="所属地区"
        width="130">
      </el-table-column>
      <el-table-column
        prop="activitydate"
        label="活动日期"
        width="100">
      </el-table-column>
      <el-table-column
        prop="storename"
        label="活动网点">
      </el-table-column>
      <!-- <el-table-column
        prop="location"
        label="活动定位"
        width="220">
      </el-table-column> -->
      <el-table-column label="邀约客户数量" width="100" prop="yaoyuenum">
        <!-- <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('tiaofu', scope.row.yaoyuenum)">查看</el-button>
        </template> -->
      </el-table-column>
      <el-table-column label="到场客户数量" width="100" prop="daochangnum">
        <!-- <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('haibao', scope.row.daochangnum)">查看</el-button>
        </template> -->
      </el-table-column>
      <el-table-column label="满期客户数量" width="100" prop="manqinum">
        <!-- <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('wuliao', scope.row.manqinum)">查看</el-button>
        </template> -->
      </el-table-column>
      <el-table-column label="意向出单客户数量" width="120" prop="yixiangnum">
        <!-- <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('qiandao', scope.row.yixiangnum)">查看</el-button>
        </template> -->
      </el-table-column>
      <!-- <el-table-column label="现场照片" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('xianchang', scope.row.xianchang)">查看</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="审核状态" width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state == '1'" type="warning" size="mini">待审核</el-tag>
          <el-tag v-else-if="scope.row.state == '2'" type="success" size="mini">已通过</el-tag>
          <el-tag v-else-if="scope.row.state == '3'" type="danger" size="mini">未通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="活动提交时间" prop="createtime" width="180"></el-table-column>
      <el-table-column label="最后审核时间" prop="examintime" width="180"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <div>
              <el-button type="text" size="mini" icon="el-icon-view" @click="showActiveDetail(scope.row)">查看详情</el-button>
            </div>
            <div>
              <el-button type="text" size="mini" icon="el-icon-download" @click="onDownloadImages(scope.row)">下载图片</el-button>
            </div>
            <div>
              <el-button type="text" size="mini" icon="el-icon-delete" @click="onRemoveActive(scope.row)">删除活动</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 查看图片 -->
    <el-dialog :visible.sync="dialogImagesVisible" @close="imageDialogClose" :show-close="false">
      <div class="show-images" style="text-align: center;">
        <el-carousel height="450px" v-if="imgType == 'xianchang'" indicator-position="outside">
          <el-carousel-item v-for="item in imgUrl" :key="item">
            <img :src="item" />
          </el-carousel-item>
        </el-carousel>
        <img v-else :src="imgUrl[0]" />
      </div>
    </el-dialog>

    <!-- 查看活动详情 -->
    <el-dialog title="活动详情" :visible.sync="dialogActiveDetailVisible" @close="imageDialogClose" :show-close="false" style="margin-top: -50px;">
      <div class="active-detail" style="text-align: center;margin-top: -20px;">
        <img v-if="activeObj.state == '1'" src="@/assets/daishen.png" class="shenpi-resuilt-img" />
        <img v-else-if="activeObj.state == '2'" src="@/assets/pass.png" class="shenpi-resuilt-img" />
        <img v-else-if="activeObj.state == '3'" src="@/assets/nopass.png" class="shenpi-resuilt-img" />
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              <div style="width: 115px;">
                <i class="el-icon-user"></i>
                规划师
              </div>
            </template>
            {{ activeObj.empname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div style="width: 145px;">
                <i class="el-icon-mobile-phone"></i>
                手机号
              </div>
            </template>
            {{ activeObj.sphone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              所属团队
            </template>
            {{ activeObj.fname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              定位信息
            </template>
            <el-tag size="mini">{{ activeObj.location }}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              邀约客户数量
            </template>
            {{ activeObj.yaoyuenum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              到场客户数量
            </template>
            {{ activeObj.daochangnum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              满期客户数量
            </template>
            {{ activeObj.manqinum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              意向出单客户数量
            </template>
            {{ activeObj.yixiangnum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-alarm-clock"></i>
              活动提交时间
            </template>
            {{ activeObj.createtime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-alarm-clock"></i>
              最后审核时间
            </template>
            {{ activeObj.examintime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-promotion"></i>
              活动网点
            </template>
            {{ activeObj.storename }}
          </el-descriptions-item>
          <el-descriptions-item v-if="activeObj.state == '3'">
            <template slot="label">
              <i class="el-icon-s-check"></i>
              驳回理由
            </template>
            {{ activeObj.cause }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="active-images-layout">
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.tiaofu}`)">
            <img :src="`${baseurl}/images/${activeObj.tiaofu}`" width="120px" height="120px">
            <div>条幅照片</div>
          </div>
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.haibao}`)">
            <img :src="`${baseurl}/images/${activeObj.haibao}`" width="120px" height="120px">
            <div>海报照片</div>
          </div>
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.wuliao}`)">
            <img :src="`${baseurl}/images/${activeObj.wuliao}`" width="120px" height="120px">
            <div>物料照片</div>
          </div>
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.qiandao}`)">
            <img :src="`${baseurl}/images/${activeObj.qiandao}`" width="120px" height="120px">
            <div>签到照片</div>
          </div>
          <div v-for="(item,index) in activeObj.xianchang" :key="index" class="active-images-item" @click="showImageDetail(`${baseurl}/images/${item}`)">
            <img :src="`${baseurl}/images/${item}`" width="120px" height="120px">
            <div>现场照片{{ index + 1 }}</div>
          </div>
        </div>
        <!-- <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="6">
            <div class="grid-content">
              <img :src="`${baseurl}/images/${activeObj.tiaofu}`" width="100px" height="100px" alt="">
            </div>
        </el-col>
          <el-col :span="6"><div class="grid-content">
            <img :src="`${baseurl}/images/${activeObj.haibao}`" alt="">
          </div></el-col>
          <el-col :span="6"><div class="grid-content">
            <img :src="`${baseurl}/images/${activeObj.qiandao}`" alt="">
          </div></el-col>
        </el-row> -->
      </div>
    </el-dialog>

    <!-- 导出本月数据弹框 -->
    <el-dialog v-loading="monthTableLoading" :title="monthDataTitle" :visible.sync="dialogMonthDataVisible" @close="dialogTableClose">
      <div class="active-detail-dialog">
        <el-table id="tableId" :data="monthTableData" height="450">
          <el-table-column
            fixed
            prop="id"
            label="ID">
          </el-table-column>
          <el-table-column
            fixed
            prop="empname"
            label="规划师">
          </el-table-column>
          <el-table-column
            prop="fname"
            label="所属团队"
            width="150">
          </el-table-column>
          <el-table-column
            prop="area"
            label="所属地区"
            width="130">
          </el-table-column>
          <el-table-column
            prop="activitydate"
            label="活动日期"
            width="200">
          </el-table-column>
          <el-table-column
            prop="storename"
            label="活动网点"
            width="400">
          </el-table-column>
          <el-table-column
            prop="location"
            label="活动定位"
            width="400">
          </el-table-column>
          <el-table-column label="邀约客户数量" prop="yaoyuenum">
          </el-table-column>
          <el-table-column label="到场客户数量" prop="daochangnum">
          </el-table-column>
          <el-table-column label="满期客户数量" prop="manqinum">
          </el-table-column>
          <el-table-column label="意向出单客户数量" prop="yixiangnum">
          </el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == '1'">待审核</span>
              <span v-else-if="scope.row.state == '2'">已通过</span>
              <span v-else-if="scope.row.state == '3'">未通过</span>
            </template>
          </el-table-column>
          <el-table-column label="活动提交时间" prop="createtime" width="180"></el-table-column>
          <el-table-column label="最后审核时间" prop="examintime" width="180"></el-table-column>
          <el-table-column label="条幅照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.tiaofu}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.tiaofu}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="海报照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.haibao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.haibao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="物料照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.wuliao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.wuliao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="签到照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.qiandao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.qiandao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in 6"
            :key="index"
            :label="`现场照片${item}`"
            width="600">
            <template slot-scope="scope">
              <a v-if="scope.row.xianchang.length >= item" :href="`${baseurl}/images/${scope.row.xianchang[index]}`" target="_blank" style="margin: 0px 5px;">
                  {{ `${baseurl}/images/${scope.row.xianchang[index]}` }}
              </a>
            </template>
          </el-table-column>
          <!-- <el-table-column label="现场照片" width="3000">
            <template slot-scope="scope">
              <a v-for="(item, index) in scope.row.xianchang" :key="index" :href="`${baseurl}/images/${item}`" target="_blank" style="margin: 0px 5px;">
                  {{ `${baseurl}/images/${item}` }}
              </a>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="active-detail-dialog-bottom">
        <el-button type="success" icon="el-icon-download" :disabled="monthTableData.length <= 0" size="mini" @click="onDownloadExcel">一键下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import global from '@/utils/global.js'
import JSZip from 'jszip'
import FileSaver from "file-saver"
import * as XLSX from "xlsx"
export default {
  name: 'ActivityPage',
  data() {
    return {
      fnameOptions: [], // 可选经理
      snameOptions: [], // 可选规划师
      employeeList: [], // 团队原数据
      allStoreList: [], // 所有网点数据
      areaList: [], // 所有地区
      area: '', // 选择地区
      fcode: '', // 选择经理
      scode: '', // 选择规划师
      month: '', // 月份
      activeState: '0', // 活动状态, 0所有活动，1待审核，2审核通过，3未通过
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imgUrl: [],
      imgType: '',
      dialogImagesVisible: false,
      dialogActiveDetailVisible: false,
      activeObj: {},
      baseurl: global.baseurl,
      downloadTitle: '',
      monthDataTitle: '', // 导出数据表头
      dialogMonthDataVisible: false, // 导出数据弹框
      monthTableData: [], // 导出数据表格
      monthTableLoading: false
    };
  },
  watch: {
    employeeList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.fnameOptions = val.filter(item => {
          return item.role == '1'
        })

        this.snameOptions = val.filter(item => {
          return item.role == '2'
        })
      }
    },
    allStoreList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.fnameOptions.forEach(f => {
            val.forEach(store => {
              if (f.empcode == store.fcode) {
                f.emparea = store.area
              }
            })
          })

          this.snameOptions.forEach(s => {
            val.forEach(store => {
              if (s.empcode == store.scode) {
                s.emparea = store.area
              }
            })
          })
        }
      }
    },
    // fnameOptions: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     if (val.length <= 0) {
    //       this.snameOptions = []
    //     }
    //   }
    // },
    // fcode(val) {
    //   if (val) {
    //     this.scode = ''
    //     if (val) {
    //       this.snameOptions = this.employeeList.filter(item => {
    //         return item.role == '2' && item.fcode == val
    //       })
    //     } else {
    //       this.snameOptions = []
    //     }
    //   }
    // },
    activeState() {
      this.currentPage = 1
      this.getData()
    }
  },
  mounted() {
    this.month = new Date()
    this.initData()
    // this.getData()
  },

  methods: {
    async initData() {
      this.loading = true
      const empResp = await this.$http({
        method: 'post',
        url: 'employee_findall'
      })
      if (empResp.errno == 0) {
        this.employeeList = empResp.data.data
      } else {
        this.$message.error('员工加载失败')
      }

      const storeResp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      if (storeResp.errno == 0) {
        this.allStoreList = storeResp.data.data
      } else {
        this.$message.error('网点加载失败')
      }

      const areaResp = await this.$http({
        method: 'post',
        url: 'area_findall'
      })
      if (areaResp.errno == 0) {
        this.areaList = areaResp.data.data
      } else {
        this.$message.error('地区加载失败')
      }

      this.getData()
    },
    getData() {
      if (!this.month) {
        this.$message.warning('请选择要查询的活动月份！')
        return
      }
      let activitydate = ''
      if (this.month) {
        // const date = new Date(this.month)
        // const Y = date.getFullYear()
        // const M = date.getMonth() + 1
        const Y = this.month.getFullYear()
        const M = this.month.getMonth() + 1
        activitydate = `${Y}/${M}/`
      }
      this.loading = true
      this.$http({
        method: 'post',
        url: 'activity_find',
        params: {
          // empname: this.sname,
          empcode: this.scode,
          activitydate,
          state:  this.activeState == '0' ? '' : this.activeState,
          // fname: this.fname,
          fcode: this.fcode,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      })
      .then(res => {
        this.loading = false
        if (res.errno == 0) {
          const { data, page, pageSize, count } = res.data
          this.tableData = data
          this.currentPage = page
          this.pageSize = pageSize
          this.total = count
          // if (this.tableData.length > 0) {
          //   this.addAreaFiled(this.tableData)
          // }
          this.tableData.forEach(item => {
            this.allStoreList.forEach(store => {
              if (store.scode == item.empcode || store.fcode == item.empcode) {
                item.area = store.area
              }
            })
          })
        } else {
          this.$message.error('活动查询异常')
        }
      })
      .catch(err =>{
        this.loading = false
        this.$message.error('活动查询异常')
        console.error('活动查询异常：',err.message);
      })
    },
    // 查询的数据添加地区字段
    addAreaFiled(list, type) {
      list.forEach(data => {
        for (let i = 0; i < this.allStoreList.length; i++) {
          const item = this.allStoreList[i]
          if (data.empcode == item.fcode || data.empcode == item.scode) {
            data.area = item.area
          }
        }
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    onSearch() {
      this.currentPage = 1
      // if (this.area || this.fcode || this.scode) {
      //   this.queryActiveByArea()
      // } else {
      //   this.getData()
      // }
      this.getData()
    },
    async queryActiveByArea() {
      this.loading = true
      let activitydate = ''
      if (this.month) {
        const Y = this.month.getFullYear()
        const M = this.month.getMonth() + 1
        activitydate = `${Y}/${M}`
      }

      const activeResp = await this.$http({
        method: 'post',
        url: 'activity_findall',
      })
      if (activeResp.errno != 0) {
        return
      }

      this.tableData = activeResp.data.data
      this.addAreaFiled(this.tableData)
      if (this.area) {
        this.tableData = this.tableData.filter(item => item.area == this.area)
      }
      if (this.fcode) {
        this.tableData = this.tableData.filter(item => item.fcode == this.fcode)
      }
      if (this.scode) {
        this.tableData = this.tableData.filter(item => item.empcode == this.scode)
      }
      if (this.month) {
        this.tableData = this.tableData.filter(item => item.activitydate.includes(activitydate))
      }
      this.currentPage = 1
      this.total = 0
      this.loading = false
    },
    updateEmpState() {
      this.tableData.forEach(item => {
        this.employeeList.forEach(emp => {
          if (item.empcode == emp.empcode) {
            item.empstate = emp.state
          }
        })
      })
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.getData()
    },
    // 查看图片弹框
    showImages(type, img) {
      this.dialogImagesVisible = true
      this.imgType = type
      this.imgUrl = []
      if (type == 'xianchang') {
        const imgArry = img.split(',')
        imgArry.forEach(item => {
          this.imgUrl.push(`${this.baseurl}/images/${item}`)
        })
      } else {
        this.imgUrl = [`${this.baseurl}/images/${img}`]
      }
    },
    // 查看图片弹框关闭
    imageDialogClose() {
      this.imgUrl = []
      this.imgType = ''
      this.activeObj = {}
    },
    // 查看活动详情
    showActiveDetail(row) {
      this.activeObj = row
      if (typeof this.activeObj.xianchang == 'string') {
        this.activeObj.xianchang =  this.activeObj.xianchang.split(',')
      }
      this.dialogActiveDetailVisible = true
    },
    // 查看大图
    showImageDetail(url) {
      var iWidth = 950 //弹出窗口的宽度
      var iHeight = 560 //弹出窗口的高度
      //获得窗口的垂直位置
      var iTop = (window.screen.availHeight - 30 - iHeight) / 2;
      //获得窗口的水平位置
      var iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
      window.open(url,'_blank','width=' + iWidth + ',height=' + iHeight + ',top=' + iTop + ',left=' + iLeft + ',menubar=no,toolbar=no, status=no,scrollbars=yes')
    },
    getImgArrayBuffer(url){
      let _this=this;
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest()
        xmlhttp.open("GET", url, true)
        xmlhttp.responseType = "blob"
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response)
          }else{
            reject(this.status)
          }
        }
        xmlhttp.send()
      })
    },
    // 下载图片
    async onDownloadImages(row) {  
      this.loading = true
     // 准备数据
      const images = []
      images.push({ title: '海报.jpg', href: `${this.baseurl}/images/${row.haibao}` })
      images.push({ title: '条幅.jpg', href: `${this.baseurl}/images/${row.tiaofu}` })
      images.push({ title: '物料.jpg', href: `${this.baseurl}/images/${row.wuliao}` })
      images.push({ title: '签到.jpg', href: `${this.baseurl}/images/${row.qiandao}` })
      let xianchang = []
      try {
        if (typeof row.xianchang == 'string') {
          xianchang = row.xianchang.split(',')
        } else {
          xianchang = row.xianchang
        }
      } catch(e) {
        this.loading = false
        this.$notify({
            title: '提示',
            type: 'error',
            message: '文件压缩失败',
            position: 'bottom-right'
          });
        return
      }
      xianchang.forEach((item, index) => {
        images.push({ title: `现场-${index+1}.jpg`, href: `${this.baseurl}/images/${item}` })
      })
      const zipFileName = row.empname + '-' + row.activitydate.replace(/\//g, '')

      // 下载照片
      // let _this = this;
      let zip = new JSZip();
      let cache = {};
      let promises = [];

      for (let item of images) {
        const promise= this.getImgArrayBuffer(item.href).then(data => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.title, data, { binary: true }); // 逐个添加文件
          cache[item.title] = data;
        });
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, zipFileName); // 利用file-saver保存文件  自定义文件名
          this.$notify({
            title: '提示',
            type: 'success',
            message: `${zipFileName}.zip 下载成功`,
            position: 'bottom-right'
          });
          this.loading = false
        })
        this.loading = false
      }).catch(res=>{
        this.loading = false
        this.$notify({
            title: '提示',
            type: 'error',
            message: '文件压缩失败',
            position: 'bottom-right'
          });
      })
    },
    // 删除活动
    onRemoveActive(row) {
      const message = `此操作将删除 ${row.empname} ${row.activitydate} 的活动，是否继续？`
      this.$confirm(message, '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('删除后数据将不能恢复，再次确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          
          this.$http({
            method: 'post',
            url: 'activity_delete',
            params: {
              id: row.id
            }
          })
          .then(res => {
            if (res.errno == 0) {
              this.$message.success('删除成功')
              this.getData()
            } else {
              this.$message.error('删除失败')
            }
            this.loading = false
          })
          .catch(err => {
            this.$message.error('网络异常，删除失败！')
            this.loading = false
          })
        }).catch(() => {})
      }).catch(() => {})
    }, 
    // 导出数据
    exportMonthData() {
      this.$router.push({
        path: '/exportActivity'
      })
      /* const year = this.month.getFullYear()
      const month = this.month.getMonth() + 1
      this.monthDataTitle = `${year}年${month}月活动总数据`
      this.dialogMonthDataVisible = true
      this.monthTableData = []

      this.monthTableLoading = true
      this.$http({
        method: 'post',
        url: 'activity_findall',
        params: {
          activitydate:  `${year}/${month}`,
          // state: '1'
        }
      })
      .then(res => {
        this.monthTableLoading = false
        if (res.errno == 0) {
          this.monthTableData = res.data.data.map(item => {
            if (item.xianchang && typeof item.xianchang == 'string') {
              item.xianchang = item.xianchang.split(',')
            }
            return item

            // if (item.details) {
            //   const details = JSON.parse(item.details)
            //   details.forEach(obj => {
            //     obj.month = item.month
            //   })
            //   this.monthTableData = this.monthTableData.concat(details)
            //   this.monthTableData.forEach(obj => {
            //     if (obj.xianchang && typeof obj.xianchang == 'string') {
            //       obj.xianchang = obj.xianchang.split(',')
            //     }
            //     this.employeeList.forEach(obj2 => {
            //       if (obj.scode == obj2.empcode) {
            //         obj.address = obj2.address
            //       }
            //     })
            //   })
            //   console.log('this.monthTableData', this.monthTableData);
            // }
          })

          this.addAreaFiled(this.monthTableData)

        //   const wbout = this.onDownloadExcel()
        //   if (wbout) {
        //     this.$message.success('下载成功')
        //   } else {
        //     this.$message.error('网络异常，下载失败')
        //   }
        //   this.dialogMonthDataVisible = false
        //   this.dialogTableClose()
        // } else {
        //   this.$message.error('活动查询异常')
        }
      })
      .catch(err =>{
        this.monthTableLoading = false
        this.$message.error('活动查询异常')
        console.error('活动查询异常：',err.message);
      }) */
    },
    // 关闭下载数据弹框
    dialogTableClose() {
      this.monthTableData = []
      this.monthDataTitle = ''
    },
    // 下载Excel数据
    onDownloadExcel() {
      //转换成excel时，使用原始的格式
				var xlsxParam = { raw: true };
				let fix = document.querySelector(".el-table__fixed");
				let wb;
        //判断有无fixed定位，如果有的话去掉，后面再加上，不然数据会重复
				if (fix) {
				  wb = XLSX.utils.table_to_book(
				  document.querySelector("#tableId").removeChild(fix),xlsxParam
				  );
				  document.querySelector("#tableId").appendChild(fix);
				} else {
          wb = XLSX.utils.table_to_book(document.querySelector("#tableId"),xlsxParam);
        }
				var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
				});
				try {
				  FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }),`${this.monthDataTitle}.xlsx`); //文件名
				} catch (e) {
				  if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

::v-deep .el-dialog {
  width: 60%;
  height: 700px;
  top: -20px;
}

.show-images {
  height: 480px;
}
.active-detail {
  position: relative;
  .shenpi-resuilt-img {
    position: absolute;
    width: 120px;
    top: -70px;
    right: 0px;
    opacity: 0.6;
  }

  .active-images-layout {
    display: flex;
    flex-wrap: wrap;
    .active-images-item {
      margin: 20px;
      cursor: pointer;
    }
  }
}

.active-detail-dialog {
  height: 480px;
  overflow-y: scroll;
}
.active-detail-dialog-bottom {
  margin-top: 20px;
  text-align: right;
}

/* 修改滚动条样式 */
.active-detail-dialog::-webkit-scrollbar {
	width: 4px;
}
.active-detail-dialog::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

</style>